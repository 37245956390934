import { SubAccountAPI } from "@/helpers/Apis/index";

const state = {
  subacc_balances: [],
  errors: [],
  isSubAccsLoaded: true,
};

const getters = {
  isSubAccBalancesLoaded(state) {
    return state.isSubAccsLoaded;
  },
  getSubAccBalances(state) {
    return state.subacc_balances;
  },
  getClientErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsSubAccBalancesLoaded: (state, payload) => {
    state.isSubAccsLoaded = payload;
  },
  setSubAccs: (state, payload) => {
    state.subacc_balances = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.subacc_balances.meta.current_page = payload.value;
  },
};

const actions = {
  fetchSubAccBalances: ({ commit }, data) => {
    commit("setIsSubAccBalancesLoaded", false);
    return new Promise((resolve, reject) => {
      SubAccountAPI.fetchSubAccBalances(data)
        .then((res) => {
          commit("setSubAccs", res.data);
          commit("setIsSubAccBalancesLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSubAccBalancesLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
