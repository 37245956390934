import { EndingBalanceAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  ledger: [],
  isLedgerLoaded: true,
  errors: [],
};

const getters = {
  getLedger(state) {
    return state.ledger;
  },
  isLedgerLoaded(state) {
    return state.isLedgerLoaded;
  },

  getAssets(state) {
    let Accounts = state.ledger;
    let Assets = Accounts.filter(function (el) {
      return el.accounttype_id == 1;
    });
    return Assets;
  },
  getLiabilities(state) {
    let Accounts = state.ledger;
    let Liabilities = Accounts.filter(function (el) {
      return el.accounttype_id == 2;
    });
    return Liabilities;
  },
  getExpenses(state) {
    let Accounts = state.ledger;
    let Expenses = Accounts.filter(function (el) {
      return el.accounttype_id == 3;
    });
    return Expenses;
  },
  getRevenues(state) {
    let Accounts = state.ledger;
    let Revenues = Accounts.filter(function (el) {
      return el.accounttype_id == 4;
    });
    return Revenues;
  },
};

const mutations = {
  setLedger: (state, payload) => {
    state.ledger = payload;
  },
  setIsLedgerLoaded: (state, payload) => {
    state.isLedgerLoaded = payload;
  },
};

const actions = {
  fetchLedger: ({ commit }, data) => {
    commit("setIsLedgerLoaded", false);
    return new Promise((resolve, reject) => {
      EndingBalanceAPI.fetchLedger(data)
        .then((res) => {
          commit("setIsLedgerLoaded", true);
          let data = res.data.data;
          commit("setLedger", data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsLedgerLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
