<template>
  <v-data-table
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
    @input="handleInput"
    :loading-text="$t('loading')"
    :no-data-text="$t('nodata')"
    class="elevation-1 tableClass"
    :class="isStriped ? 'strip_table' : ''"
    dense
    :show-select="showSelect"
    :single-select="singleSelection"
    :hide-default-footer="hideDefaultFooter"
    :items-per-page="itemsPerPage"
    mobile-breakpoint="0"
  >
    <template v-slot:top>
      <slot name="top"></slot>
    </template>

    <template v-slot:item="{ item, index }">
      <!-- :class="[
          value.some((selected) => selected.id == item.id)
            ? 'v-data-table__selected'
            : '',
          getRowClass(item, index),
        ]" -->
      <tr
        :class="[isSelected(item) ? 'v-data-table__selected' : '', getRowClass(item, index)]"
        @click="rowClicked(item, index)"
        @dblclick="rowDblClicked(item, index)"
        :style="getRowStyle"
      >
        <td v-if="showSelect" class="tableColClass text-center">
          <v-simple-checkbox
            :value="isSelected(item)"
            @click.stop="toggleSelection(item)"
            :ripple="false"
          ></v-simple-checkbox>
        </td>

        <td
          v-for="header in headers"
          :key="header.value"
          class="tableColClass"
          :class="[header.colClass || '', header.align ? `text-${header.align}` : 'text-center']"
        >
          <template v-if="$scopedSlots[`item.${header.value}`]">
            <slot :name="`item.${header.value}`" :item="item" :index="index"></slot>
          </template>
          <template v-else>
            <span
              :class="getColumnClass(item, header)"
              v-html="getCustomValue(item, header.value, index)"
            />
            <!-- <span :class="getColumnClass(item, header)">
              {{ getCustomValue(item, header.value, index) }}
            </span> -->
          </template>
        </td>
      </tr>
    </template>

    <template v-for="header in customHeaders" v-slot:[`item.${header.key}`]="slotProps">
      <slot :name="`item.${header.key}`" v-bind="slotProps"></slot>
    </template>

    <template v-slot:[`body.append`]="{ items, headers }">
      <tr v-if="shouldShowTotals" class="totals_column text-center always-at-bottom">
        <td
          v-for="(header, i) in headers"
          :key="i"
          class="tableColClass"
          :class="[header.colClass || '', header.align ? `text-${header.align}` : 'text-center']"
        >
          <strong v-if="sumColumns.includes(header.keyValue)">
            {{
              customSums.hasOwnProperty(header.keyValue)
                ? customSums[header.keyValue]
                : calculateSum(header.value, items)
            }}
          </strong>
          <span v-else>&nbsp;</span>
        </td>
      </tr>

      <slot name="body.append" v-bind="{ items, headers }"></slot>
    </template>

    <template v-slot:[`item.data-table-expand`]="slotProps">
      <slot name="item.data-table-expand" v-bind="slotProps"></slot>
    </template>

    <template v-slot:[`expanded-item`]="slotProps">
      <slot name="expanded-item" v-bind="slotProps"></slot>
    </template>

    <template v-slot:[`footer`]="slotProps">
      <slot name="footer" v-bind="slotProps"></slot>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    isStriped: {
      type: Boolean,
      default: () => true,
    },
    isStripedRelated: {
      type: Boolean,
      default: () => false,
    },
    heighlightStoreOut: {
      type: Boolean,
      default: () => false,
    },
    heightLightedRows: {
      type: Boolean,
      default: () => false,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    customHeaders: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    hideDefaultFooter: {
      type: Boolean,
      default: () => true,
    },
    isRelated: {
      type: Boolean,
      default: () => false,
    },
    showSelect: {
      type: Boolean,
      default: () => false,
    },
    singleSelection: {
      type: Boolean,
      default: () => false,
    },
    itemsPerPage: {
      type: Number,
      default: () => -1,
    },
    sumColumns: {
      type: Array,
      default: () => [],
    },
    customSums: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    shouldShowTotals() {
      return this.sumColumns && this.sumColumns.length > 0;
    },
    getRowStyle() {
      return this.isRelated
        ? this.getDarkMode
          ? "background-color: #188168"
          : "background-color: #46edc5"
        : "";
    },
    rowClasses() {
      return this.computeRowClasses(this.items);
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.value);
    },
    rowClicked(item, index) {
      // to use @click:row=""
      this.$emit("click:row", item, index);
    },
    rowDblClicked(item, index) {
      // to use @dblclick:row=""
      this.$emit("dblclick:row", item, index);
    },

    isSelected(item) {
      return this.value.some((selectedItem) => selectedItem && selectedItem.id === item.id);
    },
    toggleSelection(item) {
      if (this.singleSelection) {
        // For single selection, use the first element of the array.
        if (this.value[0] && this.value[0].id === item.id) {
          this.$emit("input", []); // Deselect if already selected
        } else {
          this.$emit("input", [item]); // Replace with the new selection
        }
      } else {
        // For multiple selection, clone the array and update it.
        const selected = [...this.value];
        const index = selected.findIndex(
          (selectedItem) => selectedItem && selectedItem.id === item.id
        );
        if (index === -1) {
          selected.push(item);
        } else {
          selected.splice(index, 1);
        }
        this.$emit("input", selected);
      }
    },
    // selectedRowClass(item) {
    //   return this.value.some((selected) => selected.id == item.id)
    //     ? "v-data-table__selected"
    //     : "";
    // },
    getCustomValue(item, headerValue, index) {
      if (headerValue === "serials") {
        return this.formatSerials(item);
      } else if (headerValue === "Number") {
        return index + 1;
      } else if (headerValue === "expiration.expire_date") {
        return this.formatExpiration(item.expiration);
      } else if (headerValue.includes(".")) {
        return this.getNestedValue(item, headerValue);
      } else {
        return item[headerValue];
      }
    },
    // formatSerials(item) {
    //   return item.newSerials
    //     ? item.newSerials.map((elem) => elem.serial).join(" , ")
    //     : item.serials
    //     ? item.serials.map((elem) => elem.serial).join(" , ")
    //     : "";
    // },
    formatSerials(item) {
      const serials = item.newSerials ? item.newSerials : item.serials ? item.serials : [];
      return serials.map((elem) => `<span class="text-no-wrap">${elem.serial}</span>`).join(", ");
    },
    formatExpiration(expiration) {
      if (!expiration) return "";
      let batchNumber = expiration?.batch_number ? ` ( ${expiration?.batch_number} )` : "";
      return `${expiration?.expire_date}` + `${batchNumber}`;
    },
    getNestedValue(obj, path) {
      return path.split(".").reduce((acc, part) => acc && acc[part], obj);
    },
    computeRowClasses(items) {
      let isCurrentlyStriped = false;
      let lastInvoiceId = null;
      let lastInvoicetypeId = null;
      let skipFirstGroup = true; // Flag to skip highlighting the first group

      return items.map((item, index) => {
        // Handle heighlightStoreOut condition
        if (this.heighlightStoreOut && item.store_out_id) {
          return this.getDarkMode ? "brown lighten-1" : "brown lighten-3";
        }
        if (this.heightLightedRows && item.highlighted) {
          return this.getDarkMode ? "brown lighten-1 " : "brown lighten-3";
        }

        if (this.isStripedRelated) {
          // If the invoice_id or invoicetype_id changes, toggle the striping
          if (item.invoice_id !== lastInvoiceId || item.invoicetype_id !== lastInvoicetypeId) {
            // Only toggle striping after the first group is processed
            if (!skipFirstGroup) {
              isCurrentlyStriped = !isCurrentlyStriped;
            }

            // After processing the first group, stop skipping
            skipFirstGroup = false;
            // Update the last seen invoice and type IDs
            lastInvoiceId = item.invoice_id;
            lastInvoicetypeId = item.invoicetype_id;
          }

          // Apply the striping class
          return isCurrentlyStriped ? "related-stripe" : "";
        }

        return "";
      });
    },

    getRowClass(item, index) {
      return this.rowClasses[index];
    },
    getColumnClass(item, header) {
      // not using yet settings negative to column only not row
      const value = item[header.value];
      if (this.heighlightStoreOut && typeof value === "number" && value < 0) {
        return "negative-column";
      }
      return "";
    },
    calculateSum(columnName, items) {
      return this.toFixedIfNecessary(
        items.reduce(
          (acc, item) =>
            acc +
            (+item[columnName] || 0) +
            this.getRelatedDetailsSum(item.related_details, columnName),
          0
        ),
        2
      );
    },
    getRelatedDetailsSum(relatedDetails, columnName) {
      return Array.isArray(relatedDetails)
        ? relatedDetails.reduce((acc, detail) => acc + (detail[columnName] || 0), 0)
        : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.tableClass {
  border: solid 2px var(--v-secondary-base);
}
.strip_table tbody tr:nth-of-type(even) {
  background-color: rgba(156, 145, 145, 0.3);
}
.related-stripe {
  background-color: rgba(156, 145, 145, 0.3);
}
::v-deep .table-dateMinWidth {
  min-width: 100px;
}
::v-deep .table-nameMinWidth {
  min-width: 160px;
}
::v-deep .table-columnWidth100 {
  width: 100px;
}
::v-deep .table-percentWidth {
  width: 80px;
  min-width: unset !important;
}

.tableColClass {
  min-width: 80px;
  padding: 0 3px !important;
}

::v-deep .store-select {
  appearance: auto;
  color: var(--v-textColor-base);
  background-color: transparent;
  outline: none !important;
  width: 100%;
  min-width: 170px;
  min-height: 35px;
  border: solid 1px var(--v-textColor-base);
  text-align: center;
}
::v-deep select option {
  background-color: var(--v-selectBG-base) !important;
  color: var(--v-textColor-base);
  text-align: center;
}
::v-deep .basicBtnProps {
  height: 100%;
  width: 100%;
  text-align: center;
  border: solid 1px var(--v-textColor-base);
  outline: none !important;
}
::v-deep .barcode {
  @extend .basicBtnProps;
  min-width: 140px;
  max-height: 35px;
}
::v-deep .notes {
  @extend .basicBtnProps;
  min-width: 220px;
  max-height: 35px;
}
::v-deep .v-data-table-header {
  tr th {
    background-color: var(--v-primary-base) !important;
    // padding: 0px 3px !important;
    white-space: nowrap;

    border: solid 0.1rem black;
    border-bottom: solid 0.1rem black !important;
  }
  span {
    color: #fff !important;
    font-size: 0.9rem;
  }
  i {
    color: #fff !important;
  }
}
// th {
//   border: solid 0.1rem black;
//   border-bottom: solid 0.1rem black !important;
//   span {
//     font-size: 0.9rem;
//   }
// }
td {
  border: solid 0.1rem black;
  border-bottom: solid 0.1rem black !important;
}

::v-deep .v-data-table--fixed-header thead th {
  background-color: var(--v-primary-base) !important;
  span {
    color: #fff !important;
  }
  i {
    color: #fff !important;
  }
}
::v-deep tr.v-data-table__selected {
  background: rgb(84, 100, 173) !important;
}

.always-at-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1;
}

::v-deep .totals_column {
  background-color: var(--v-primary-base) !important;
  > td {
    // height: 26px !important;
    color: var(--v-white-base) !important;
    border: solid 0.1rem black !important;
  }
}

::v-deep .miniDetailsTable .v-data-table-header tr th {
  background-color: var(--v-primary-base) !important;
}
::v-deep .miniDetailsTable .v-data-table__wrapper {
  display: flex;
  justify-content: center;
  overflow: hidden !important;
  table {
    width: 90% !important;
    @media (max-width: 600px) {
      width: 100% !important;
    }
  }
}
</style>
