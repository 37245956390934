import auth from "@/router/middleware/auth";

function lazyLoad(folderName, view) {
  return () => import(/* webpackChunkName: "sale" */ `@containers/${folderName}/${view}.vue`);
}

const InvoicesRoutes = [
  {
    path: "/sales",
    name: "Sales",
    component: lazyLoad("Invoice/Sales", "Sales"),
    meta: {
      middleware: [auth],
    },
  },
  // {
  //   path: "/sales/:id(\\d+)",
  //   name: "SingleSale",
  //   component: lazyLoad("Invoice/Sales", "Sale"),
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  // {
  //   path: "/sales/create",
  //   name: "CreateSale",
  //   component: lazyLoad("Invoice/Sales", "Sale"),
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  {
    path: "/sales/:id(\\d+|create)",
    name: "SingleSale",
    component: lazyLoad("Invoice/Sales", "Sale"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/sales/deleted",
    name: "SalesDeleted",
    component: lazyLoad("Invoice", "Sales/Reports/SalesDeleted"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/salesreturns",
    name: "SalesReturns",
    component: lazyLoad("Invoice/SalesReturns", "SalesReturns"),
    meta: {
      middleware: [auth],
    },
  },
  // {
  //   path: "/salesreturns/:id(\\d+)",
  //   name: "SingleSaleReturn",
  //   component: lazyLoad("Invoice/SalesReturns", "SaleReturns"),
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  // {
  //   path: "/salesreturns/create",
  //   name: "CreateSaleReturn",
  //   component: lazyLoad("Invoice/SalesReturns", "SaleReturns"),
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  {
    path: "/salesreturns/:id(\\d+|create)",
    name: "SingleSaleReturn",
    component: lazyLoad("Invoice/SalesReturns", "SaleReturns"),
    meta: {
      middleware: [auth],
    },
  },

  // Cashiers  Cashiers  Cashiers  Cashiers  Cashiers
  {
    path: "/cashier",
    name: "Cashier",
    component: lazyLoad("Cashier", "CashierPage"),
    // meta: {
    //   middleware: [auth],
    // },
  },
  {
    path: "/cashierdaily",
    name: "Cashiers",
    component: lazyLoad("Invoice/Cashiers", "Cashiers"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/cashierdaily/:id(\\d+)",
    name: "SingleCashier",
    component: lazyLoad("Invoice/Cashiers", "Cashier"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/cashiers/deleted",
    name: "CashiersDeleted",
    component: lazyLoad("Invoice", "Cashiers/CashiersDeleted"),
    meta: {
      middleware: [auth],
    },
  },

  //////////// Sales Reports Sales Reports Sales Reports Sales Reports
  {
    path: "/sales/itemsearch",
    name: "SaleItemSearch",
    component: lazyLoad("Invoice", "Sales/Reports/SaleItem"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/sales/item-grouped",
    name: "SaleItemGrouped",
    component: lazyLoad("Invoice", "Sales/Reports/SaleItemGrouped"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/invoicesprofits",
    name: "InvoicesProdits",
    component: lazyLoad("Invoice", "Sales/Reports/InvoicesProfit"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/sales/itemprofits",
    name: "SalesItemsProfit",
    component: lazyLoad("Invoice", "Sales/Reports/ItemsProfits"),
    meta: {
      middleware: [auth],
    },
  },
  // {
  //   path: "/stagnantitems",
  //   name: "StagnantItems",
  //   component: lazyLoad("Invoice", "Sales/Reports/StagnantItems"),
  //   meta: {
  //     middleware: [auth],
  //   },
  // },

  //////////// Sales Returns Reports
  {
    path: "/salesreturns/itemsearch",
    name: "SaleReturnItemSearch",
    component: lazyLoad("Invoice", "SalesReturns/Reports/SaleReturnItemSearch"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/salesreturns/deleted",
    name: "SalesReturnsDeleted",
    component: lazyLoad("Invoice", "SalesReturns/Reports/SalesReturnsDeleted"),
    meta: {
      middleware: [auth],
    },
  },

  /// Employees / Sales Agent Reports
  {
    path: "/salesagentscollections",
    name: "SalesAgentsCollections",
    component: lazyLoad("Constructions/Employees", "Reports/SalesAgentsCollections"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/salesagentsclients",
    name: "SalesAgentsClients",
    component: lazyLoad("Constructions/Employees", "Reports/SalesAgentsClients"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/salesagentsitems",
    name: "SalesAgentsItems",
    component: lazyLoad("Constructions/Employees", "Reports/SalesAgentsItems"),
    meta: {
      middleware: [auth],
    },
  },
  /// Employees Reports
  {
    path: "/salesagentcommission",
    name: "SalesAgentCommission",
    component: lazyLoad("Constructions/Employees", "Reports/SalesAgentCommission"),
    meta: {
      middleware: [auth],
    },
  },
];

export default InvoicesRoutes;
