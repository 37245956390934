import { BeginningStoreAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  beginningstoredetails: [],

  isBeginningStoreLoaded: true,
  errors: [],
};

const getters = {
  getBeginningStoreDetails(state) {
    return state.beginningstoredetails;
  },

  isBeginningStoreLoaded(state) {
    return state.isBeginningStoreLoaded;
  },
  getBeginningDetailErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsBeginningStoreLoaded: (state, payload) => {
    state.isBeginningStoreLoaded = payload;
  },
  setBeginningStoreDetails: (state, payload) => {
    state.beginningstoredetails = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setCurrentPage(state, payload) {
    state.beginningstoredetails.meta.current_page = payload.value;
  },
  updateTotal(state, payload) {
    state.beginningstoredetails.sum_of_totals = payload;
  },
  updateDetails(state, payload) {
    let detailData = state.beginningstoredetails.data;
    let data = payload.data;

    let detailIndex = detailData.findIndex((o) => o.id === data.id);
    if (detailIndex !== -1) {
      state.beginningstoredetails.data.splice(detailIndex, 1, { ...data });
    } else {
      state.beginningstoredetails.data.push(data);
    }
  },
};

const actions = {
  fetchBeginningStoreDetails: ({ commit }, data) => {
    commit("setIsBeginningStoreLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      BeginningStoreAPI.fetchBeginningStoreDetails(data)
        .then((res) => {
          commit("setBeginningStoreDetails", res.data);
          commit("setIsBeginningStoreLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsBeginningStoreLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },

  fetchBeginningDetails: ({ commit }) => {
    commit("setIsBeginningStoreLoaded", false);
    BeginningStoreAPI.fetchBeginningDetails()
      .then((res) => {
        commit("setBeginningStoreDetails", res.data);
        commit("setIsBeginningStoreLoaded", true);
      })
      .catch((error) => {
        commit("setIsBeginningStoreLoaded", true);
      });
  },

  createBeginningDetail: ({ commit }, data) => {
    commit("setIsBeginningStoreLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      BeginningStoreAPI.createBeginningDetail(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsBeginningStoreLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("updateDetails", { data: res.data.data.detail, index: data.index });
          commit("updateTotal", res.data.data.sum_of_totals);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsBeginningStoreLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateBeginningDetail: ({ commit }, data) => {
    commit("setIsBeginningStoreLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      BeginningStoreAPI.updateBeginningDetail(data)
        .then((res) => {
          commit("setErrors", []);
          commit("App/setAppLoader", false, { root: true });
          commit("setIsBeginningStoreLoaded", true);
          commit("updateDetails", { data: res.data.data.detail, index: data.index });
          commit("updateTotal", res.data.data.sum_of_totals);
          resolve(res.data);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsBeginningStoreLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  deleteBeginningDetail: ({ commit }, id) => {
    commit("setIsBeginningStoreLoaded", false);
    return new Promise((resolve, reject) => {
      BeginningStoreAPI.deleteBeginningDetail(id)
        .then((res) => {
          commit("setIsBeginningStoreLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsBeginningStoreLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
