import baseUrl from "@/constants/API";

export const SubAccountAPI = {
  fetchBasicSubAcc(id) {
    return axios.get(baseUrl() + `/subaccounts/basicsubacc/${id}`);
  },

  fetchBasicSubAccPaginate(data) {
    return axios.post(baseUrl() + `/subaccounts/basics?page=${data.pageNumber}`, data.data);
    // return axios.post(baseUrl() + `/subaccounts/basics?cursor=${data.next_cursor}`, data.data);
  },
  fetchSubAccs(data) {
    return axios.post(baseUrl() + `/subaccounts/get?page=${data.pageNumber}`, data.data);
  },

  createSubAcc(data) {
    return axios.post(baseUrl() + `/subaccounts/create`, data);
  },
  updateSubAcc(data) {
    return axios.post(baseUrl() + `/subaccounts/update/${data.id}`, data.data);
  },
  deleteSubAcc(data) {
    return axios.delete(baseUrl() + `/subaccounts/delete/${data}`);
  },

  fetchSubAccBalance(id) {
    return axios.get(baseUrl() + `/subaccounts/getbalance/${id}`);
  },

  fetchMainAccSelectedReport(data) {
    return axios.post(baseUrl() + `/subaccounts/subaccsreport`, data);
  },
  fetchMainAccSelectedSum(data) {
    return axios.post(baseUrl() + `/subaccounts/subaccssum`, data);
  },
  fetchSubAccByMainAccSumPaginate(data) {
    return axios.post(
      baseUrl() + `/subaccounts/subaccssumpaginate?page=${data.pageNumber}`,
      data.data
    );
  },

  fetchMainAccSelectedBasic(data) {
    return axios.post(baseUrl() + `/subaccounts/subaccsbasic`, data);
  },

  fetchSaleAccounts() {
    return axios.get(baseUrl() + `/subaccounts/saleaccounts`);
  },
  fetchSaleReturnAccounts() {
    return axios.get(baseUrl() + `/subaccounts/salereturnaccounts`);
  },
  fetchPurchaseAccounts() {
    return axios.get(baseUrl() + `/subaccounts/purchaseaccounts`);
  },
  fetchPurchaseReturnAccounts() {
    return axios.get(baseUrl() + `/subaccounts/purchasereturnaccounts`);
  },
  fetchOtherRevenuesAccounts() {
    return axios.get(baseUrl() + `/subaccounts/otherrevenuesaccounts`);
  },

  fetchSubAccBalances(data) {
    return axios.post(baseUrl() + `/subaccounts/balances?page=${data.pageNumber}`, data.data);
  },
};
