import baseUrl from "@/constants/API";

export const EndingBalanceAPI = {
  fetchFinalBalance(data) {
    return axios.post(baseUrl() + "/balancereport/finalbalancesheet", data);
  },
  fetchPeriodBalance(data) {
    return axios.post(baseUrl() + "/balancereport/periodbalancesheet", data);
  },
  loadPeriodBalance(data) {
    return axios.post(baseUrl() + "/balancereport/load-periodbalancesheet", data);
  },

  fetchFinancialPosition(data) {
    return axios.post(baseUrl() + "/balancereport/financial-position", data);
  },
  fetchPeriodFinancialPosition(data) {
    return axios.post(baseUrl() + "/balancereport/period-financial-position", data);
  },
  loadPeriodFinancialPosition(data) {
    return axios.post(baseUrl() + "/balancereport/load-period-financial-position", data);
  },

  fetchLedger(data) {
    return axios.post(baseUrl() + "/balancereport/ledger", data);
  },
};
