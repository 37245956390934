import auth from "@/router/middleware/auth";
import permission from "@/router/middleware/permission";

function lazyLoad(folderName, view) {
  return () => import(/* webpackChunkName: "entries" */ `@containers/${folderName}/${view}.vue`);
}

const Entries = [
  {
    path: "/entries",
    name: "Entries",
    component: lazyLoad("Entry", "Entries"),
    meta: {
      middleware: [auth, permission("entry-view")],
    },
    // children: [
    //   {
    //     path: ":id(\\d+)",
    //     name: "SingleEntry",
    //     component: lazyLoad("Entry", "Entry"),
    //   },
    //   {
    //     path: "create",
    //     name: "CreateEntry",
    //     component: lazyLoad("Entry", "Entry"),
    //   },
    // ],
  },
  // {
  //   path: "/entries/:id(\\d+)",
  //   name: "SingleEntry",
  //   component: lazyLoad("Entry", "Entry"),
  //   middleware: [auth, permission("entry-view")],
  // },
  // {
  //   path: "/entries/create",
  //   name: "CreateEntry",
  //   component: lazyLoad("Entry", "Entry"),
  //   middleware: [auth, permission("entry-view")],
  // },
  {
    path: "/entries/:id(\\d+|create)",
    name: "SingleEntry",
    component: lazyLoad("Entry", "Entry"),
    meta: {
      middleware: [auth, permission("entry-view")],
    },
  },
  {
    path: "/draweraction",
    name: "DrawerAction",
    component: lazyLoad("Constructions/Drawer", "DrawerAction"),
    meta: {
      middleware: [auth],
    },
  },

  //entry Reports
  {
    path: "/entriesreport",
    name: "EntryReport",
    component: lazyLoad("Entry", "Reports/EntryReport"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/ledger",
    name: "ledger",
    component: lazyLoad("Entry", "Reports/Ledger"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/finalbalancesheet",
    name: "FinalBalanceSheet",
    component: lazyLoad("Entry/Reports", "FinalBalanceSheet"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/period-balancesheet",
    name: "PeriodBalanceSheet",
    component: lazyLoad("Entry/Reports", "PeriodBalanceSheet"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/financialposition",
    name: "FinancialPosition",
    component: lazyLoad("Entry/Reports", "FinancialPosition"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/period-financialposition",
    name: "PeriodFinancialPosition",
    component: lazyLoad("Entry/Reports", "PeriodFinancialPosition"),
    meta: {
      middleware: [auth],
    },
  },

  //////////// Clients Reports
  {
    path: "/clientsreceivable",
    name: "ClientsReceivables",
    component: lazyLoad("Constructions/Client", "Reports/ClientsReceivable"),
    meta: {
      middleware: [auth],
    },
  },

  //////////// Suppliers Reports
  {
    path: "/supplierspayable",
    name: "SuppliersPayable",
    component: lazyLoad("Constructions/Supplier", "Reports/SuppliersPayable"),
    meta: {
      middleware: [auth],
    },
  },

  // Notes Notes Notes Notes Notes Notes Notes Notes
  {
    path: "/notespayable",
    name: "NotesPayable",
    component: lazyLoad("Notes", "Payables"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/notesreceivable",
    name: "NotesReceivable",
    component: lazyLoad("Notes", "Receivables"),
    meta: {
      middleware: [auth],
    },
  },
];

export default Entries;
