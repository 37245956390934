const CompanyPermissions = {
  sale_management: "sale_management",
  purchase_management: "purchase_management",
  accounting_management: "accounting_management",
  maintenance_management: "maintenance_management",
  cleanning_management: "cleanning_management",
  production_management: "production_management",
  restaurant_management: "restaurant_management",

  // included in level 2
  storeinout_management: "storeinout_management",
  notes_management: "notes_management",
  store_transfer_management: "store_transfer_management",
  fixedasset_management: "fixedasset_management",
  // included in level 3
  order_management: "order_management",
  reservation_management: "reservation_management",
  advanced_excel_management: "advanced_excel_management",
};

const level1 = [];
const level2 = [
  ...level1,
  "storeinout_management",
  "notes_management",
  "store_transfer_management",
];
const level3 = [
  ...level2,
  "order_management", // saleorder purchaseorder
  "reservation_management",
  "advanced_excel_management",
  "period_balance_sheet",
];

const LevelPermissions = {
  1: [],
  2: level2,
  3: level3,
};

export { CompanyPermissions, LevelPermissions };
