// import { InvoiceTypeIds } from "@/helpers/Constants/General";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import { FreeProductionAPI } from "@/helpers/Apis/Productions/FreeProductions";
import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";

const state = {
  production: {},

  errors: [],
  productionDetailsErrors: [],
  isProductionLoaded: true,
  isEntriesLoaded: true,
};

const getters = {
  getProduction(state) {
    return state.production;
  },

  isProductionLoaded(state) {
    return state.isProductionLoaded;
  },

  getProductionErrors(state) {
    return state.errors;
  },

  getProductionDetailsErrors(state) {
    return state.productionDetailsErrors;
  },

  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },

  productionTotals(state) {
    // If there are no invoice_details, return zeros formatted to 2 decimals.
    if (!state.production?.invoice_details) {
      return {
        totalMaterialUnits: 0,
        totalMaterialPieces: 0,
        totalMaterials: 0,
        totalProductUnits: 0,
        totalProductPieces: 0,
        totalProducts: 0,
      };
    }

    // Reduce through all invoice_details only once.
    const totals = state.production.invoice_details.reduce(
      (acc, detail) => {
        // If it's a material, add to material totals.
        if (detail.store_out_id != null) {
          acc.totalMaterialUnits += +detail.unit;
          acc.totalMaterialPieces += +detail.piece;
          acc.totalMaterials += +detail.total;
        }
        // If it's a product, add to product totals.
        if (detail.store_in_id != null) {
          acc.totalProductUnits += +detail.unit;
          acc.totalProductPieces += +detail.piece;
          acc.totalProducts += +detail.total;
        }
        return acc;
      },
      {
        totalMaterialUnits: 0,
        totalMaterialPieces: 0,
        totalMaterials: 0,
        totalProductUnits: 0,
        totalProductPieces: 0,
        totalProducts: 0,
      }
    );

    // Format all totals using toFixedIfNecessary.
    Object.keys(totals).forEach((key) => {
      totals[key] = toFixedIfNecessary(totals[key], 2);
    });

    return totals;
  },
};

const mutations = {
  setProduction: (state, payload) => {
    state.production = payload;
  },

  setIsProductionLoaded: (state, payload) => {
    state.isProductionLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setExtraEntries(state, payload) {
    state.production.invoice_entries_extra = payload;
  },

  setNewDetailPrices(state, payload) {
    state.production.invoice_details = state.production.invoice_details.map((detail) => {
      // Find an update for this detail based on id.
      const update = payload.find((el) => el.id === detail.id);
      // If there is an update, return a new object with merged values.
      return update
        ? {
            ...detail,
            price: update.price,
            unit_price: update.unit_price,
            piece_price: update.piece_price,
            total: update.total,
          }
        : detail;
    });
  },

  setIsEntriesLoaded(state, payload) {
    state.isEntriesLoaded = payload;
  },
};

const actions = {
  fetchFreeProduction: ({ commit }, id) => {
    commit("setIsProductionLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      FreeProductionAPI.fetchFreeProduction(id)
        .then((res) => {
          commit("setProduction", res.data.data);
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsProductionLoaded", true);
          reject(error);
        });
    });
  },
  previousInvoice: ({ commit }, id) => {
    commit("setIsProductionLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      FreeProductionAPI.previousFreeProduction(id)
        .then((res) => {
          commit("setProduction", res.data.data);
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);
          router.push({
            path: `/productions/free/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsProductionLoaded", true);
          reject(error);
        });
    });
  },
  nextInvoice: ({ commit }, id) => {
    commit("setIsProductionLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      FreeProductionAPI.nextFreeProduction(id)
        .then((res) => {
          commit("setProduction", res.data.data);
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);
          router.push({
            path: `/productions/free/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsProductionLoaded", true);
          reject(error);
        });
    });
  },

  createFreeProduction: ({ commit }, data) => {
    commit("setIsProductionLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      FreeProductionAPI.createFreeProduction(data)
        .then((res) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);

          let invoice = res.data.data;
          commit("setProduction", invoice);
          router.push({
            path: `/productions/free/${invoice.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateFreeProduction: ({ commit }, data) => {
    commit("setIsProductionLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      FreeProductionAPI.updateFreeProduction(data)
        .then((res) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);

          let invoice = res.data.data;
          commit("setProduction", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
  deleteProduction: ({ commit }, data) => {
    commit("setIsProductionLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      FreeProductionAPI.deleteProduction(data)
        .then((res) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  createProdEntry: ({ state, commit }, data) => {
    commit("setIsEntriesLoaded", false);
    let invoiceData = state.production;
    let mergedData = { ...data, invoiceData: invoiceData };
    return new Promise((resolve, reject) => {
      EntryDetailAPI.createProdEntry(mergedData)
        .then((res) => {
          commit("setIsEntriesLoaded", true);
          commit("setErrors", []);

          let resultData = res.data.data;
          state.production.expenses = resultData.invoice.expenses;
          commit("setNewDetailPrices", resultData.details);
          resolve(resultData);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteProdEntry: ({ state, commit }, id) => {
    commit("setIsEntriesLoaded", false);
    return new Promise((resolve, reject) => {
      EntryDetailAPI.deleteProdEntry(id)
        .then((res) => {
          commit("setIsEntriesLoaded", true);
          commit("setErrors", []);
          let resultData = res.data.data;
          state.production.expenses = resultData.invoice.expenses;
          commit("setNewDetailPrices", resultData.details);
          resolve(resultData);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
