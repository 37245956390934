import Vue from "vue";
import Vuex from "vuex";
import App from "./modules/App/App";
import DBSettings from "./modules/Database/DBSettings";
import Database from "./modules/Database/Database";
import DBDefaults from "./modules/Database/DBDefaults";

// IndexDB Headers
// import TableHeaders from "./modules/IndexDB/Headers/TableHeaders";

import darkToggler from "./modules/darkToggler";
import UsersController from "./modules/Users/UsersController";
import ClosedAccounts from "./modules/Users/ClosedAccounts";

import Notifications from "./modules/Users/Notifications";
import Sockets from "./modules/Sockets/Sockets";

import BeginningHead from "./modules/Beginning/BeginningHead";
import BeginningStores from "./modules/Beginning/BeginningStores";
import BeginningStoreExcel from "./modules/Beginning/BeginningStoreExcel";
import BeginningEntries from "./modules/Beginning/BeginningEntries";
import BeginningEntryExcel from "./modules/Beginning/BeginningEntryExcel";

import User from "./modules/Users/User";
import Company from "./modules/Users/Company";
import Categories from "./modules/Categories/Categories";
import CategoriesExcel from "./modules/Categories/CategoriesExcel";
import Items from "./modules/Items/Items";
import ItemsExcel from "./modules/Items/ItemsExcel";
import ItemsList from "./modules/Items/ItemsList";
import ItemsPriceList from "./modules/Items/ItemsPriceList";
import ItemsListPrice from "./modules/Items/ItemsListPrice";

import PriceDisplay from "./modules/Items/PriceDisplay";
import ItemsProportions from "./modules/Items/ItemsProportions";
import ItemPackages from "./modules/Items/ItemPackages";

import Serials from "./modules/Items/Serials/Serials";
import SerialTransactions from "./modules/Items/Serials/SerialTransactions";
import SerialsFetch from "./modules/Items/Serials/SerialsFetch";

import Expires from "./modules/Items/Expires/Expires";
import ExpiresFetch from "./modules/Items/Expires/ExpiresFetch";
import ExpireQuantities from "./modules/Items/Expires/ExpireQuantities";
import ExpireTransactions from "./modules/Items/Expires/ExpireTransactions";

import ClientClassifications from "./modules/Classifications/ClientClassifications";
import ItemClassifications from "./modules/Classifications/ItemClassifications";

import Clients from "./modules/Clients/Clients";
import ClientsExcel from "./modules/Clients/ClientsExcel";
import ClientsSalesAgents from "./modules/Clients/ClientsSalesAgents";
import ClientsReceivable from "./modules/Clients/Reports/ClientsReceivable";

import Employees from "./modules/Employees/Employees";
import EmployeeJobs from "./modules/Employees/EmployeeJobs";
import EmployeesExcel from "./modules/Employees/EmployeesExcel";
import SalesAgentsCollections from "./modules/Employees/Reports/SalesAgentsCollections";
import SalesAgentsClients from "./modules/Employees/Reports/SalesAgentsClients";
import SalesAgentsItems from "./modules/Employees/Reports/SalesAgentsItems";

import Suppliers from "./modules/Suppliers/Suppliers";
import SuppliersExcel from "./modules/Suppliers/SuppliersExcel";
import SuppliersPayable from "./modules/Suppliers/Reports/SuppliersPayable";

import Branches from "./modules/Branches/Branches";
import Drawers from "./modules/Drawers/Drawers";
import FixedAssets from "./modules/FixedAssets/FixedAssets";

import Stores from "./modules/Stores/Stores";
import StoreLog from "./modules/Stores/StoreLog";
import StoreReports from "./modules/Stores/Reports/StoreReports";
import Inventory from "./modules/Inventory/Inventory";
import GeneralInventory from "./modules/Inventory/GeneralInventory";
import RestockInventory from "./modules/Inventory/RestockInventory";

import StoreTransfers from "./modules/StoreTransfers/StoreTransfers";
import StoreTransfersDetails from "./modules/StoreTransfers/StoreTransfersDetails";
import StoreTransferReport from "./modules/StoreTransfers/StoreTransferReport";
import StoreTransfersDeleted from "./modules/StoreTransfers/StoreTransfersDeleted";

import States from "./modules/Areas/States";
import Cities from "./modules/Areas/Cities";
import Districts from "./modules/Areas/Districts";

import DefaultAccs from "./modules/MainAccs/DefaultAccs";
import MainAccs from "./modules/MainAccs/MainAccs";
import SubAccs from "./modules/SubAccs/SubAccs";
import SubAccBalances from "./modules/SubAccs/SubAccBalances";

import EntriesReport from "./modules/Entries/EntriesReport";
import Entries from "./modules/Entries/Entries";
import EntryDetails from "./modules/Entries/EntryDetails";
import EntryReport from "./modules/Entries/Reports/EntryReport";
import DrawerAction from "./modules/Entries/DrawerAction";

// STORE INS / OUTS
import StoreIns from "./modules/Invoices/StoreIns/StoreIns";
import StoreInDetails from "./modules/Invoices/StoreIns/StoreInDetails";
import StoreInsDeleted from "./modules/Invoices/StoreIns/StoreInsDeleted";
import StoreInReport from "./modules/Invoices/StoreIns/Reports/StoreInReport";
import StoreOuts from "./modules/Invoices/StoreOuts/StoreOuts";
import StoreOutDetails from "./modules/Invoices/StoreOuts/StoreOutDetails";
import StoreOutsDeleted from "./modules/Invoices/StoreOuts/StoreOutsDeleted";
import StoreOutReport from "./modules/Invoices/StoreOuts/Reports/StoreOutReport";

import SaleCharts from "./modules/Charts/Sales/SaleCharts";
import SaleReport from "./modules/Invoices/Sales/Reports/SaleReport";
import ItemSaleReport from "./modules/Invoices/Sales/Reports/ItemSaleReport";
import ItemSaleGroupedReport from "./modules/Invoices/Sales/Reports/ItemSaleGroupedReport";

import Sales from "./modules/Invoices/Sales/Sales";
import SaleDetails from "./modules/Invoices/Sales/SaleDetails";
import SalesDeleted from "./modules/Invoices/Sales/SalesDeleted";
import ItemsProfits from "./modules/Invoices/Sales/Reports/Profits/ItemsProfits";
import InvoicesProfits from "./modules/Invoices/Sales/Reports/Profits/InvoicesProfits";

import ReservationReport from "./modules/Reservations/Reports/ReservationReport";
import Reservations from "./modules/Reservations/Reservations";
import ReservationDetails from "./modules/Reservations/ReservationDetails";
import ReservationsDeleted from "./modules/Reservations/ReservationsDeleted";

import SaleOrderReport from "./modules/Orders/SaleOrders/Reports/SaleOrderReport";
import SaleOrders from "./modules/Orders/SaleOrders/SaleOrders";
import SaleOrderDetails from "./modules/Orders/SaleOrders/SaleOrderDetails";
import SaleOrdersDeleted from "./modules/Orders/SaleOrders/SaleOrdersDeleted";

import PurchaseOrderReport from "./modules/Orders/PurchaseOrders/Reports/PurchaseOrderReport";
import PurchaseOrders from "./modules/Orders/PurchaseOrders/PurchaseOrders";
import PurchaseOrderDetails from "./modules/Orders/PurchaseOrders/PurchaseOrderDetails";
import PurchaseOrdersDeleted from "./modules/Orders/PurchaseOrders/PurchaseOrdersDeleted";

import PurchaseReport from "./modules/Invoices/Purchases/Reports/PurchaseReport";
import ItemPurchaseReport from "./modules/Invoices/Purchases/Reports/ItemPurchaseReport";
import ItemPurchaseGroupedReport from "./modules/Invoices/Purchases/Reports/ItemPurchaseGroupedReport";
import Purchases from "./modules/Invoices/Purchases/Purchases";
import PurchaseDetails from "./modules/Invoices/Purchases/PurchaseDetails";
import PurchasesDeleted from "./modules/Invoices/Purchases/PurchasesDeleted";

import RelatedReturn from "./modules/Invoices/RelatedReturn";

import SaleReturnReport from "./modules/Invoices/SalesReturns/Reports/SaleReturnReport";
import SaleReturns from "./modules/Invoices/SalesReturns/SaleReturns";
import SalesReturnDetails from "./modules/Invoices/SalesReturns/SalesReturnDetails";
import SalesReturnsDeleted from "./modules/Invoices/SalesReturns/SalesReturnsDeleted";

import PurchaseReturnReport from "./modules/Invoices/PurchasesReturns/Reports/PurchaseReturnReport";
import PurchaseReturns from "./modules/Invoices/PurchasesReturns/PurchaseReturns";
import PurchasesReturnDetails from "./modules/Invoices/PurchasesReturns/PurchasesReturnDetails";
import PurchasesReturnsDeleted from "./modules/Invoices/PurchasesReturns/PurchasesReturnsDeleted";

import ClientSupplierList from "./modules/ClientSupplierList/ClientSupplierList";

import Cashiers from "./modules/Invoices/Cashiers/Cashiers";
import CashierReport from "./modules/Invoices/Cashiers/Reports/CashierReport";
import CashierDetails from "./modules/Invoices/Cashiers/CashierDetails";
import CashiersDeleted from "./modules/Invoices/Cashiers/CashiersDeleted";

/////////////////////////////// Cashier IndexDB
import CashierPage from "./modules/IndexDB/Cashiers/CashierPage";
import CashierItems from "./modules/IndexDB/Cashiers/CashierItems";

import NoteAccounts from "./modules/Notes/NoteAccounts";
import Payables from "./modules/Notes/Payables";
import Receivables from "./modules/Notes/Receivables";

import Maintenances from "./modules/Maintenances/Maintenances";
import MaintenanceDetails from "./modules/Maintenances/MaintenanceDetails";
import MaintenanceClients from "./modules/Maintenances/MaintenanceClients";
import DeviceTypes from "./modules/Maintenances/DeviceTypes";
import MaintenanceQuestions from "./modules/Maintenances/MaintenanceQuestions";
import MaintenanceReport from "./modules/Maintenances/Reports/MaintenanceReport";
import MaintenancesDeleted from "./modules/Maintenances/MaintenancesDeleted";

// Cleanning Cleanning Cleanning Cleanning Cleanning
import CleanningInvoices from "./modules/CleanningInvoices/CleanningInvoices";
import CleanningClients from "./modules/CleanningInvoices/CleanningClients";
import CleanningClientsReceivable from "./modules/CleanningInvoices/Reports/CleanningClientsReceivable";
import CleanningSalesReport from "./modules/CleanningInvoices/Reports/CleanningSalesReport";
import CleanningClientsReport from "./modules/CleanningInvoices/Reports/CleanningClientsReport";
import CleanningInvoicesCollection from "./modules/CleanningInvoices/Reports/CleanningInvoicesCollection";

// Productions Productions Productions Productions Productions
import FreeProductions from "./modules/Productions/FreeProductions";
import ProportionProductions from "./modules/Productions/ProportionProductions";
import ProductionDetails from "./modules/Productions/ProductionDetails";
import ProductionsDeleted from "./modules/Productions/ProductionsDeleted";
import FreeProductionReport from "./modules/Productions/Reports/FreeProductionReport";
import ProportionProductionReport from "./modules/Productions/Reports/ProportionProductionReport";

import ProductionProportions from "./modules/Productions/ProductionProportions/ProductionProportions";
import ProductionProportionMaterials from "./modules/Productions/ProductionProportions/ProductionProportionMaterials";

import FinalBalance from "./modules/BalanceReports/FinalBalance";
import PeriodBalance from "./modules/BalanceReports/PeriodBalance";
import FinancialPosition from "./modules/BalanceReports/FinancialPosition";
import PeriodFinancialPosition from "./modules/BalanceReports/PeriodFinancialPosition";

import Ledger from "./modules/BalanceReports/Ledger";

// Restaurant Stuff
import RestaurantCouriers from "./modules/Restaurant/RestaurantCouriers";
import RestaurantTables from "./modules/Restaurant/RestaurantTables";
import RestaurantCategories from "./modules/Restaurant/RestaurantCategories";
import RestaurantMeals from "./modules/Restaurant/RestaurantMeals";
import RestaurantShifts from "./modules/Restaurant/RestaurantShifts";
import RestaurantOrder from "./modules/Restaurant/Orders/RestaurantOrder";
import RestaurantKitchenScreen from "./modules/Restaurant/Orders/RestaurantKitchenScreen";
import RestaurantOrderReport from "./modules/Restaurant/Orders/Reports/RestaurantOrderReport";
import RestaurantOrdersDeleted from "./modules/Restaurant/Orders/Reports/RestaurantOrdersDeleted";

import RestaurantReservationReport from "./modules/Restaurant/Reservations/RestaurantReservationReport";
import RestaurantReservation from "./modules/Restaurant/Reservations/RestaurantReservation";

import RestaurantOrderProfits from "./modules/Restaurant/Orders/Reports/Profits/RestaurantOrderProfits";
import RestaurantMealProfits from "./modules/Restaurant/Orders/Reports/Profits/RestaurantMealProfits";
import RestaurantAddonProfits from "./modules/Restaurant/Orders/Reports/Profits/RestaurantAddonProfits";

import RestaurantRecipeTransactions from "./modules/Restaurant/Orders/Reports/Transactions/RestaurantRecipeTransactions";
import RestaurantMealTransactions from "./modules/Restaurant/Orders/Reports/Transactions/RestaurantMealTransactions";
import RestaurantAddonTransactions from "./modules/Restaurant/Orders/Reports/Transactions/RestaurantAddonTransactions";

// import ProfitLoss from "./modules/EndingBalance/ProfitLoss";
// import BalanceSheet from "./modules/EndingBalance/BalanceSheet";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // APP SETTINGS
    App,
    DBSettings,
    Database,
    DBDefaults,
    darkToggler,

    // IndexDB Headers
    // TableHeaders,

    Notifications,
    Sockets,
    User,
    UsersController,
    ClosedAccounts,
    Company,
    Categories,
    CategoriesExcel,
    Items,
    ItemsExcel,
    PriceDisplay,
    ItemClassifications,
    ItemPackages,

    // item lists
    ItemsList,
    ItemsPriceList,
    ItemsListPrice,
    ItemsProportions,

    Suppliers,
    SuppliersExcel,
    SuppliersPayable,

    ClientClassifications,
    Branches,
    Drawers,
    FixedAssets,

    Employees,
    EmployeeJobs,
    EmployeesExcel,
    SalesAgentsCollections,
    SalesAgentsClients,
    SalesAgentsItems,

    Serials,
    SerialTransactions,
    SerialsFetch,
    Expires,
    ExpiresFetch,
    ExpireQuantities,
    ExpireTransactions,

    Stores,
    StoreLog,
    StoreReports,

    Clients,
    ClientsExcel,
    ClientsSalesAgents,
    ClientsReceivable,

    States,
    Cities,
    Districts,

    // DATA TYPES !!!
    DefaultAccs,
    MainAccs,
    SubAccs,
    SubAccBalances,

    EntriesReport,
    Entries,
    EntryDetails,
    EntryReport,
    DrawerAction,

    ClientSupplierList,

    Inventory,
    GeneralInventory,
    RestockInventory,

    //  Charts  Charts !!!
    SaleCharts,

    // BEGINNING DATA
    BeginningHead,
    BeginningStores,
    BeginningStoreExcel,
    BeginningEntries,
    BeginningEntryExcel,

    // sale invoices
    Sales,
    SaleDetails,
    SalesDeleted,

    ReservationReport,
    Reservations,
    ReservationDetails,
    ReservationsDeleted,

    SaleOrderReport,
    SaleOrders,
    SaleOrderDetails,
    SaleOrdersDeleted,

    PurchaseOrderReport,
    PurchaseOrders,
    PurchaseOrderDetails,
    PurchaseOrdersDeleted,

    SaleReturns,
    SalesReturnDetails,
    SalesReturnsDeleted,

    // purchase invoices
    Purchases,
    PurchaseDetails,
    PurchasesDeleted,

    RelatedReturn,
    PurchaseReturns,
    PurchasesReturnDetails,
    PurchasesReturnsDeleted,

    // CASHIERS
    Cashiers,
    CashierReport,
    CashierDetails,
    CashiersDeleted,

    CashierPage,
    CashierItems,

    //store outs/ins
    StoreIns,
    StoreInDetails,
    StoreInsDeleted,
    StoreInReport,
    StoreOuts,
    StoreOutDetails,
    StoreOutsDeleted,
    StoreOutReport,

    // STORE TRANSFERS
    StoreTransfers,
    StoreTransfersDetails,
    StoreTransferReport,
    StoreTransfersDeleted,

    //// INVOICE REPORTS
    ItemsProfits,
    InvoicesProfits,
    PurchaseReport,
    ItemPurchaseReport,
    ItemPurchaseGroupedReport,

    SaleReport,
    ItemSaleReport,
    ItemSaleGroupedReport,
    PurchaseReturnReport,
    SaleReturnReport,

    // Notes
    NoteAccounts,
    Payables,
    Receivables,

    // Maintenances
    Maintenances,
    MaintenanceDetails,
    MaintenanceClients,
    DeviceTypes,
    MaintenanceQuestions,
    MaintenanceReport,
    MaintenancesDeleted,

    CleanningInvoices,
    CleanningClients,
    CleanningClientsReceivable,
    CleanningSalesReport,
    CleanningClientsReport,
    CleanningInvoicesCollection,

    // Productions
    FreeProductions,
    ProportionProductions,

    ProductionDetails,
    ProductionsDeleted,
    FreeProductionReport,
    ProportionProductionReport,
    ProductionProportions,
    ProductionProportionMaterials,

    //EndingBalance
    FinalBalance,
    PeriodBalance,
    FinancialPosition,
    PeriodFinancialPosition,
    Ledger,

    // Restaurant
    RestaurantCouriers,
    RestaurantTables,
    RestaurantCategories,
    RestaurantMeals,
    RestaurantShifts,
    RestaurantOrder,
    RestaurantKitchenScreen,
    RestaurantOrderReport,
    RestaurantOrdersDeleted,

    RestaurantReservationReport,
    RestaurantReservation,

    RestaurantOrderProfits,
    RestaurantMealProfits,
    RestaurantAddonProfits,

    RestaurantRecipeTransactions,
    RestaurantMealTransactions,
    RestaurantAddonTransactions,
  },
});
