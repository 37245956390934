<template>
  <v-badge
    :content="getNotifications && getNotifications.length ? getNotifications.length : 0"
    :value="getNotifications && getNotifications.length ? getNotifications.length : 0"
    color="red darken-2"
    :overlap="true"
    offset-x="10"
  >
    <!-- class="badge" -->
    <v-menu
      bottom
      min-width="320px"
      max-width="320px"
      :close-on-content-click="false"
      rounded
      offset-y
    >
      <template v-slot:activator="{ on: menuOn }">
        <v-tooltip right color="toolTipBgColor">
          <template v-slot:activator="{ on: tooltipOn, attrs }">
            <!-- Merged the menu's and tooltip's event listeners for the icon -->
            <!-- <v-icon
              v-bind="attrs"
              v-on="{
                ...menuOn,
                ...tooltipOn,
              }"
            >
              mdi-bell
            </v-icon> -->
            <v-btn
              v-bind="attrs"
              v-on="{
                ...menuOn,
                ...tooltipOn,
              }"
              icon
              class="notificationIcon no-hover"
              x-small
            >
              <v-icon dense> mdi-bell </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("notifications") }}</span>
        </v-tooltip>
      </template>

      <v-card class="">
        <v-card-text class="cardStyle pt-0 px-2 pb-2" v-if="isNotificationsLoaded">
          <div class="d-flex justify-end">
            <v-tooltip
              v-if="getNotifications && getNotifications.length > 0"
              top
              color="toolTipBgColor"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :ripple="false"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :loading="!isNotificationsLoaded"
                  @click="deleteNotifications()"
                  class="px-1 no-hover"
                >
                  <v-icon icon color="red darken-2"> mdi-delete-alert </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("deleteall") }}</span>
            </v-tooltip>

            <v-tooltip top color="toolTipBgColor">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :ripple="false"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :loading="!isNotificationsLoaded"
                  @click="fetchNotifications()"
                  class="px-1 no-hover"
                >
                  <v-icon icon color="success"> mdi-reload </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("reload") }}</span>
            </v-tooltip>
          </div>

          <v-list-item-content class="listStyle pt-0 pb-2">
            <div v-if="!getNotifications || getNotifications.length == 0">{{ $t("nodata") }}</div>
            <div
              v-else
              class="text-center"
              v-for="notification in getNotifications"
              :key="notification.id"
            >
              <v-divider class="mb-2"></v-divider>

              <div class="d-flex justify-space-between align-center">
                <h4 class="bodyText">{{ notification.message }}</h4>
                <v-divider vertical class="mx-1"></v-divider>
                <h5 class="dateText">{{ notification.created_at }}</h5>

                <v-divider vertical class="mx-1"></v-divider>
                <!-- <v-icon v-if="!notification.read" class="mx-1" color="green" fab>
                  mdi-checkbox-marked-circle-outline
                </v-icon> -->
                <v-tooltip top color="toolTipBgColor">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="deleteNotification(notification.id)"
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                    >
                      mdi-alpha-x-circle-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("delete") }}</span>
                </v-tooltip>
              </div>
            </div>
          </v-list-item-content>
        </v-card-text>

        <div v-else class="cardStyle h-100 d-flex align-center justify-center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </div>
      </v-card>
    </v-menu>
  </v-badge>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: [],
  components: {},
  computed: {
    ...mapGetters("Notifications", ["getNotifications"]),
    ...mapGetters("Notifications", ["isNotificationsLoaded"]),
  },
  methods: {
    ...mapActions("Notifications", ["fetchNotifications"]),
    ...mapActions("Notifications", ["deleteNotification"]),
    ...mapActions("Notifications", ["deleteNotifications"]),
    // accountLogout() {
    //   this.$emit("accountLogout");
    // },
  },
};
</script>

<style lang="scss" scoped>
// .badge {
//   width: 30px;
//   height: 32px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   i {
//     font-size: 2rem !important;
//   }
// }
.cardStyle {
  text-align: center;
  min-height: 120px;

  .listStyle {
    max-height: 340px;
    overflow: auto;
    overflow-x: hidden;
    .bodyText {
      width: 180px;
      text-align: center;
    }
    .dateText {
      width: 80px;
      text-align: center;
    }
  }
}
::v-deep.theme--dark.v-icon:focus::after {
  opacity: 0;
}

.notificationIcon {
  background-color: var(--v-primary-base);
  color: var(--v-white-base) !important;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  i {
    font-size: 1.5rem !important;
    color: white;
  }
}
.no-hover::before {
  content: none !important; /* Remove the pseudo-element */
}

.no-hover:hover::before {
  background: none !important; /* Ensure no hover effect */
}
</style>
