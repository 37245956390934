import { EndingBalanceAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";

const state = {
  reportData: [],
  // trade: [],
  // profitLoss: [],
  // balanceSheet: [],

  // stockValue: 0,

  // isContinuousInventory:
  //   JSON.parse(localStorage.getItem("user"))?.database_settings["db-iscontinuousinventory"] ||
  //   false,

  isEndBalanceLoaded: true,
  errors: [],
};

const getters = {
  getReportData(state) {
    return state.reportData || [];
  },
  getTrade(state) {
    return state.reportData?.trade || {};
  },
  getProfitLoss(state) {
    return state.reportData?.profit_loss || {};
  },
  getBalanceSheet(state) {
    return state.reportData?.balance_sheet || {};
  },
  isEndBalanceLoaded(state) {
    return state.isEndBalanceLoaded;
  },

  // getReportData(state) {
  //   return state.reportData;
  // },
  // leftTradeAccounts(state) {
  //   /// اصول /  مصروفات
  //   let Accounts = state.trade;
  //   let leftAccounts = Accounts.filter(function (el) {
  //     return el.accounttype_id == 3 || el.accounttype_id == 1;
  //   });
  //   return leftAccounts;
  // },
  // rightTradeAccounts(state) {
  //   /// خصوم / ايرادات
  //   let Accounts = state.trade;
  //   let rightAccounts = Accounts.filter(function (el) {
  //     return el.accounttype_id == 4 || el.accounttype_id == 2;
  //   });

  //   if (!state.isContinuousInventory) {
  //     rightAccounts.push({
  //       accounttype_id: 2,
  //       defaultacc_id: 7,
  //       name: i18n.t("stockvalue"),
  //       showsin_id: 1,
  //       accounts_debit: 0,
  //       accounts_credit: state.stockValue,
  //       total_balance: -state.stockValue || 0,
  //     });
  //   }
  //   return rightAccounts;
  // },

  // isEndBalanceLoaded(state) {
  //   return state.isEndBalanceLoaded;
  // },

  // EndTradingProfits(state, getters) {
  //   /// خصوم / ايرادات
  //   let leftAccounts = getters.leftTradeAccounts;
  //   /// اصول /  مصروفات
  //   let rightAccounts = getters.rightTradeAccounts;
  //   let leftAccountsTotal = leftAccounts.reduce((accumulator, object) => {
  //     return accumulator + object.total_balance;
  //   }, 0);

  //   let rightAccountsTotal = rightAccounts.reduce((accumulator, object) => {
  //     return accumulator + object.total_balance;
  //   }, 0);
  //   let result = rightAccountsTotal - -leftAccountsTotal;
  //   return toFixedIfNecessary(-result, 2);
  // },

  // leftProfitLossAccounts(state, getters) {
  //   /// اصول /  مصروفات
  //   let Accounts = state.profitLoss;
  //   let leftAccounts = Accounts.filter(function (el) {
  //     return el.accounttype_id == 3 || el.accounttype_id == 1;
  //   });
  //   return leftAccounts;
  // },
  // rightProfitLossAccounts(state, getters) {
  //   /// خصوم / ايرادات
  //   let TradeResult = -getters.EndTradingProfits;
  //   let Accounts = state.profitLoss;
  //   let rightAccounts = Accounts.filter(function (el) {
  //     return el.accounttype_id == 4 || el.accounttype_id == 2;
  //   });
  //   rightAccounts.push({
  //     accounttype_id: 2,
  //     name: i18n.t("traderesult"),
  //     accounts_debit: TradeResult > 0 ? TradeResult : 0,
  //     accounts_credit: TradeResult < 0 ? -TradeResult : 0,
  //     total_balance: TradeResult || 0,
  //   });

  //   return rightAccounts;
  // },
  // // ProfitLossAccounts
  // EndProfitLoss(state, getters) {
  //   /// خصوم / ايرادات
  //   let leftAccounts = getters.leftProfitLossAccounts;
  //   /// اصول /  مصروفات
  //   let rightAccounts = getters.rightProfitLossAccounts;
  //   let leftAccountsTotal = leftAccounts?.reduce((accumulator, object) => {
  //     return accumulator + object.total_balance;
  //   }, 0);

  //   let rightAccountsTotal = rightAccounts?.reduce((accumulator, object) => {
  //     return accumulator + object.total_balance;
  //   }, 0);

  //   let result = rightAccountsTotal - -leftAccountsTotal;
  //   return toFixedIfNecessary(-result, 2);
  //   // return toFixedIfNecessary(rightAccountsTotal - leftAccountsTotal, 2) || 0;
  // },

  // // BalanceSheet
  // leftBalanceSheetAccounts(state, getters) {
  //   /// اصول /  مصروفات
  //   let Accounts = state.balanceSheet;
  //   let leftAccounts = Accounts.filter(function (el) {
  //     return el.accounttype_id == 3 || el.accounttype_id == 1;
  //   });
  //   if (!state.isContinuousInventory) {
  //     leftAccounts.push({
  //       accounttype_id: 2,
  //       defaultacc_id: 7,
  //       name: i18n.t("stockvalue"),
  //       showsin_id: 1,
  //       accounts_debit: state.stockValue,
  //       accounts_credit: 0,
  //       total_balance: state.stockValue,
  //     });
  //   }
  //   return leftAccounts;
  // },
  // rightBalanceSheetAccounts(state, getters) {
  //   /// خصوم / ايرادات
  //   let TradeResult = -getters.EndProfitLoss;
  //   let Accounts = state.balanceSheet;
  //   let rightAccounts = Accounts.filter(function (el) {
  //     return el.accounttype_id == 4 || el.accounttype_id == 2;
  //   });
  //   rightAccounts.push({
  //     accounttype_id: 2,
  //     name: i18n.t("profitloss"),
  //     accounts_debit: TradeResult > 0 ? TradeResult : 0,
  //     accounts_credit: TradeResult < 0 ? -TradeResult : 0,
  //     total_balance: TradeResult || 0,
  //   });
  //   return rightAccounts;
  // },

  // EndBalanceSheet(state, getters) {
  //   /// خصوم / ايرادات
  //   let leftAccounts = getters.leftBalanceSheetAccounts;
  //   /// اصول /  مصروفات
  //   let rightAccounts = getters.rightBalanceSheetAccounts;
  //   let leftAccountsTotal = leftAccounts.reduce((accumulator, object) => {
  //     return accumulator + object.total_balance;
  //   }, 0);

  //   let rightAccountsTotal = rightAccounts.reduce((accumulator, object) => {
  //     return accumulator + object.total_balance;
  //   }, 0);

  //   let result = rightAccountsTotal - -leftAccountsTotal;
  //   return toFixedIfNecessary(-result, 2);
  // },
};

const mutations = {
  setEndBalance: (state, payload) => {
    state.reportData = payload;

    // let accounts = payload[0];
    // state.stockValue = payload[1];
    // let tradeAccounts = accounts.filter(function (el) {
    //   return el.showsin_id == 1;
    // });
    // let profitLossAccounts = accounts.filter(function (el) {
    //   return el.showsin_id == 2;
    // });
    // let balanceSheetAccounts = accounts.filter(function (el) {
    //   return el.showsin_id == 3;
    // });
    // state.trade = tradeAccounts;
    // state.profitLoss = profitLossAccounts;
    // state.balanceSheet = balanceSheetAccounts;
  },

  setIsEndBalanceLoaded: (state, payload) => {
    state.isEndBalanceLoaded = payload;
  },
};

const actions = {
  fetchPeriodBalance: ({ commit }, data) => {
    commit("setIsEndBalanceLoaded", false);
    return new Promise((resolve, reject) => {
      EndingBalanceAPI.fetchPeriodBalance(data)
        .then((res) => {
          commit("setIsEndBalanceLoaded", true);
          let data = res.data;
          commit("setEndBalance", data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsEndBalanceLoaded", true);
          reject(error);
        });
    });
  },
  loadPeriodBalance: ({ commit }, data) => {
    commit("setIsEndBalanceLoaded", false);
    return new Promise((resolve, reject) => {
      EndingBalanceAPI.loadPeriodBalance(data)
        .then((res) => {
          commit("setIsEndBalanceLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsEndBalanceLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
