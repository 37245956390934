import { EndingBalanceAPI } from "@/helpers/Apis/index";

const state = {
  reportData: [],
  isEndBalanceLoaded: true,
  errors: [],

  searchData: {},
};

const getters = {
  getReportData(state) {
    return state.reportData || [];
  },
  getIncomeStatement(state) {
    return state.reportData?.income_statement || {};
  },
  getFinancialPosition(state) {
    return state.reportData?.financial_position || {};
  },
  isEndBalanceLoaded(state) {
    return state.isEndBalanceLoaded;
  },

  getSearchData(state) {
    return state.searchData;
  },
};

const mutations = {
  setEndBalance: (state, payload) => {
    state.reportData = payload;
  },
  setIsEndBalanceLoaded: (state, payload) => {
    state.isEndBalanceLoaded = payload;
  },

  setSearchData: (state, payload) => {
    state.searchData = payload;
  },
};

const actions = {
  fetchFinancialPosition: ({ commit }, data) => {
    commit("setIsEndBalanceLoaded", false);
    return new Promise((resolve, reject) => {
      EndingBalanceAPI.fetchFinancialPosition(data)
        .then((res) => {
          commit("setSearchData", data);
          commit("setIsEndBalanceLoaded", true);
          commit("setEndBalance", res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsEndBalanceLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
