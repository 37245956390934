import auth from "@/router/middleware/auth";

function lazyLoad(folderName, view) {
  return () =>
    import(/* webpackChunkName: "reservation" */ `@containers/${folderName}/${view}.vue`);
}

const InvoicesRoutes = [
  {
    path: "/reservations",
    name: "Reservations",
    component: lazyLoad("Reservations", "Reservations"),
    meta: {
      middleware: [auth],
    },
  },
  // {
  //   path: "/reservations/:id(\\d+)",
  //   name: "SingleReservation",
  //   component: lazyLoad("Reservations", "Reservation"),
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  // {
  //   path: "/reservations/create",
  //   name: "CreateReservation",
  //   component: lazyLoad("Reservations", "Reservation"),
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  {
    path: "/reservations/:id(\\d+|create)",
    name: "SingleReservation",
    component: lazyLoad("Reservations", "Reservation"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/reservations/deleted",
    name: "ReservationsDeleted",
    component: lazyLoad("Reservations", "Reports/ReservationsDeleted"),
    meta: {
      middleware: [auth],
    },
  },
];

export default InvoicesRoutes;
